<template>
  <div class="columns is-centered mb-0">
    <div class="column is-3 box shadowdrop mt-5">
      <p class="p-5 has-text-centered mb-0">
        <img src="logo.svg" style="width: 30%;" />
      </p>
      <p class="pb-3 pl-5 pr-5 has-text-centered mb-2">
        <img src="logo/dd.svg" style="width: 80%;" />
      </p>
      <form class="section pt-0 pb-5">
        <b-field :label="t('common.user')" label-position="on-border">
          <b-input icon="account" custom-class="has-text-centered" v-model="payload.email"></b-input>
        </b-field>
        <b-field :label="t('common.password')" label-position="on-border">
          <b-input
            icon="lock"
            v-model="payload.password"
            type="password"
            password-reveal
            custom-class="has-text-centered"
          ></b-input>
        </b-field>
        <p class="has-text-centered">
          <b-button :loading="isLoading" type="is-primary" @click="login">{{t('auth.login')}}</b-button>
        </p>
        <p class="mt-5">
          <LanguageDropdown class="mt-1"></LanguageDropdown>
          <b-button
            style="width: 7rem;"
            icon-left="help-circle"
            size="is-small"
            class="is-pulled-right mt-1"
            @click="$router.push({ name: 'password-request' })"
          >{{t('auth.reset')}}</b-button>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { Action } from "../../store/config_actions";
import Commons from "@/mixins/commons";
import LanguageDropdown from "@/components/tools/LanguageDropdown";

export default {
  name: "AuthLogin",
  mixins: [Commons],
  components: { LanguageDropdown },

  data: function () {
    return {
      payload: {
        email: "",
        password: "",
      },
      isLoading: false,
    };
  },

  computed: {
    session() {
      return this.$store.state.auth.session;
    },

    isAdmin() {
      if (this.session && this.session.roles.includes("Admin")) return true;
      else return false;
    },

    isTeacher() {
      if (this.session && this.session.roles.includes("Teacher")) return true;
      else return false;
    },

    isStudent() {
      if (this.session && this.session.roles.includes("Student")) return true;
      else return false;
    },
  },

  methods: {
    login() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.AUTH_LOGIN, this.payload)
        .then(() => {
          this.isLoading = false;
          this.$router.push({ name: "dashboard" });
          this.getIniitailData();
        })
        .catch((error) => {
          this.apiProblem(error);
          this.isLoading = false;
        });
    },

    getIniitailData() {
      this.$store
        .dispatch(Action.DEEPZOOM_CONFIG_GET)
        .then(() => {
          //this.successSnackbar('Pobrano configuraję deepZoom');
        })
        .catch(() => {
          //this.mDangerSnack(error);
        });

      this.$store
        .dispatch(Action.AFM_CONFIG_GET)
        .then(() => {
          //this.successSnackbar('Pobrano configuraję deepZoom');
        })
        .catch(() => {
          //this.mDangerSnack(error);
        });

      if (this.isAdmin) {
        this.$store
          .dispatch(Action.TEACHER_GET_ALL)
          .then(() => {
            //this.mSuccessSnack('Pobrano wykładowców');
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
          });

        this.$store
          .dispatch(Action.USEREXAM_GET_ALL)
          .then(() => {
            //this.mSuccessSnack('Pobrano wykładowców');
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
          });
      }

      if (this.isTeacher || this.isAdmin) {
        this.$store
          .dispatch(Action.STUDENT_GET_ALL)
          .then(() => {
            //this.mSuccessSnack("Pobrano wykładowców");
          })
          .catch(() => {
            //this.mDangerSnack(error.toString());
          });

        this.$store
          .dispatch(Action.MEDIA_GET_MY)
          .then(() => { })
          .catch(() => {
            //this.mDangerSnack(error.toString());
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.shadowdrop {
  -webkit-box-shadow: 0px 5px 17px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 17px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 17px 1px rgba(0, 0, 0, 0.75);
}
</style>
