<template>
  <b-dropdown aria-role="list" position="is-top-right">
    <p class="button is-small" slot="trigger" role="button" style="width: 7rem;">
      <b-icon icon="earth"></b-icon>
      <span>{{currentLanguage}}</span>
    </p>

    <b-dropdown-item
      v-for="lang in $ml.list"
      :key="lang"
      @click="changeLanguage(lang)"
      aria-role="listitem"
    >{{ lang }}</b-dropdown-item>
  </b-dropdown>
</template>

<script>

/** Language handler component. Changes language and saves language cookies. */
export default {
  name: 'LanguageDropdown',

  data: function () {
    return {
      currentLanguage: 'polski'
    }
  },

  created: function () {
    this.reloadCookieLanguage();
  },

  methods: {
    /** Changes global language. */
    changeLanguage(lang) {
      // Change language.
      this.$ml.change(lang)

      // Set cookie.
      this.$cookies.set("DD_LANG", lang)
      this.currentLanguage = lang

      // Show an awesome snackbar!
      this.$buefy.snackbar.open({
        message: this.t('common.languageChanged', { lang: lang }),
        queue: false
      })
    },

    /** Checks for language cookie. */
    reloadCookieLanguage: function () {
      // Change to saved language if found.
      var cookieLanguage = this.$cookies.get("DD_LANG")
      if (cookieLanguage != null) {
        this.$ml.change(cookieLanguage)
        this.currentLanguage = cookieLanguage
      }
    }
  }
}

</script>

<style scoped lang="scss">
</style>
