<template>
  <div>
    <AuthLogin class="mt-0" />
  </div>
</template>

<script>
// @ is an alias to /src
import AuthLogin from '@/components/auth/AuthLogin.vue'

export default {
  name: 'Home',
  components: {
    AuthLogin
  }
}
</script>

<style>
</style>
